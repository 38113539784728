.jumper-card-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.jumper-card{
    margin: 1rem 0rem;
    background-color: #B3DA44B3;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 537px;
    padding: 1rem 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.jumper-header-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.jumper-header{
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
}

.jumper-image-wrapper{
    border-radius: 50%;
    overflow: hidden;
    width: 90px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: rotate(10deg);
    animation-name: movingAvacado;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes movingAvacado {
    0% {transform:rotate(10deg);}
    100% {transform: rotate(20deg);}
  }

.jumper-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.jumper-input{
    width: 85%;
    border: 0px;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.jumper-icons-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
}

.jumper-icons-selector{
    background-color: rgb(237, 243, 238);
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.jumper-icons-selected{
    background-color: #b2da44;
    border:1px solid #2e3f00;
}

.jumper-icons{
    width: 100%;
    max-width: 140px;
}
.jumper-info{
    margin-right: 0.5rem;
    cursor: pointer;
    position: relative;
}

.jumper-info-arrow{
    position:absolute;
    z-index: 1;
    left: 2px;
    top: 20px;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    visibility: hidden;
}
.jumper-info-tooltip{
    position:absolute;
    z-index: 1;
    right: -230px;
    top: 25px;
    background-color: black;
    padding: 0.5rem 1rem;
    font-weight: 400;
    border-radius: 4px;
    color: white;
    width: 250px;
    visibility: hidden;
}
.jumper-info:hover .jumper-info-tooltip {
    visibility: visible;
  }
.jumper-info:hover .jumper-info-arrow {
visibility: visible;
}
.jumper-estimate{
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.jumper-sizes{
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    justify-content: center;
}
.jumper-size-wrapper{
    width: 92px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 4px;
}
.jumper-size-label{
    font-size: 12px;
    margin-bottom: 4px;
}

.jumper-size-input{
    width: 100%;
    border: 0px;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.jumper-button-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: 1rem;
}

.jumper-button{
    width: 85%;
    max-width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #5E7718;
    color: white;
    padding: 0.5rem 1rem;
    border: 0px;
    margin-bottom: 2rem;
}