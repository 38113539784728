.jumper-table-wrapper {
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    padding: 18px 2rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  }
  
  .jumper-page-wrapper {
    height: calc(100vh - 125px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .jumper-filter-wrapper {
    width: 90%;
    border: 1px solid black;
    border-radius: 5px;
    padding: 18px 2rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    color: blue;
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19); */
  }
  
  .jumper-page-search-wrapper {
    /* height: 10vh; */
    width: 100%;
    /* padding-bottom: 1rem; */
  }
  
  .jumper-page-filter-wrapper {
    /* height: 10vh; */
    width: 90%;
    border-width: 0px;
    border: none;
    padding-bottom: 1rem;
  }
  
  .jumper-page-search {
    height: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px solid black;
    border-radius: 2px;
  }
  .jumper-page-details-wrapper {
    height: calc(90vh - 125px);
    width: 90%;
    overflow-y: scroll;
  }
  
  .jumper-table-personal-info-wrapper {
    display: flex;
    flex-direction: row;
    text-align: left;
    width: 100%;
  }
  
  .jumper-table-personal-info {
    flex: 0.5;
  }
  
  .jumper-table-comment-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.5rem;
  }
  
  .jumper-table-size-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.5rem;
    flex-wrap: wrap;
  }
  
  .jumper-table-size {
    border: 1px solid black;
    background-color: #b3da44b3;
    border-radius: 2px;
    width: 60px;
    padding: 12px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 2px;
    position: relative;
  }
  
  .jumper-table-size-label {
    position: absolute;
    font-size: 10px;
    left: 3px;
    top: 1px;
  }
  
  .jumper-table-actions {
    position: absolute;
    font-size: 20px;
    top: 4px;
    right: 8px;
  }
  .jumper-table-delete {
    color: red;
    cursor: pointer;
    display: inline-block;
    margin-left: 4px;
  }
  .jumper-table-edit {
    color: blue;
    cursor: pointer;
    display: inline-block;
  }
  
  @media (max-width: 700px) {
    .jumper-table-personal-info-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .jumper-table-personal-info {
      flex: 1;
    }
  }
  
  .export-button {
    position: absolute;
    font-size: 20px;
    top: 4px;
    right: 8px;
  }
  