.daysOfWeek {
	border: 1px solid black;
	width: 14.3%;
	padding: 10px;
	text-align: center;
}

.current-month {
	background-color: green;
	margin-bottom: 0.5rem;
	border-radius: 1rem;
	text-align: center;
	cursor: pointer;
}

.change-next-month-icon {
	margin-left: 0px;
	margin-top: 6px;
	cursor: pointer;
}

.change-prev-month-icon {
	margin-right: 0px;
	margin-top: 6px;
	cursor: pointer;
}

.today-btn {
	position: relative;
	top: 0.5rem;
	color: #ffffff;
	margin-bottom: 1rem;
}

.weeks-pointer {
	margin: 0px;
	text-align: center;
	border: 1.5px solid black;
}
.dates-pointer {
	margin: -0.8rem;
	padding: 3px;
	text-align: center;
	color: white;
	margin-bottom: 0.8rem;
}

.leaveRequestMode {
	padding: 0;
	border-radius: 2px;
	margin-top: 2px;
	font-size: 14px;
}

.statusR {
	width: 25%;
	text-align: center;
	border: 1px solid #969696;
	cursor: pointer;
}
