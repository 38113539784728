.remove-btn {
	margin-right: 0.5rem;
}

.fade-enter {
	opacity: 0.01;
}

.fate-enter-active {
	opacity: 1;
	transition: opacity 1000ms ease-in;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0.01;
	transition: opacity 1000ms ease-in;
}
#descriptionBox {
	/* It is used for description box in all pages */
	height: 100px;
}

.notificationSection {
	font-size: 0.8rem;
	color: blue;
	display: block;
	position: absolute;
	right: 5px;
	top: 45px;
	z-index: 1;
	width: 270px;
}

.rowAlign {
	vertical-align: middle;
	padding: 5px;
	text-align: center;
}

.addRow {
	margin-left: 0.5rem;
	color: #4287f5;
	cursor: pointer;
	display: inline-block;
}

.inactive {
	background-color: #6e9eeb;
	color: rgb(255, 255, 255) !important;
}

.active {
	background-color: #2e7bf7;
	border: 0.5px solid #2e7bf7;
	color: #fff !important;
}

.img-container {
	max-width: 400px;

	height: 300px;
}

.exportButton {
	color: rgb(58, 129, 0);
	background-color: transparent;
	border: 2px solid;
	border-color: rgb(58, 129, 0);
	border-radius: 7px;
}

.tableLink {
	cursor: pointer;
	color: rgb(0, 110, 184);
}
/* USED IN PDF COMPONENT  */
k-pdf-export p {
    /* border: 2px dashed #aaa; */
	/* padding: 10px;*/
	background-color: #2e7bf7;
}
/* .div { */
	/* background-color: #2e7bf7; */
/* } */
